<template>
  <div class="d-flex align-center justify-center secondary-lighten rounded">
    <v-img v-if="imageSrc" class="mb-4" :src="imageSrc" height="60" contain />
    <div v-else class="d-flex align-center text-caption secondary-darken--text">
      {{ $t('ads.no_icon_selected') }}
    </div>
  </div>
</template>

<script>
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'DirectLinkCreativePreview',
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    computed: {
      imageSrc() {
        return this.ad.model.icon?.src ?? ''
      }
    }
  }
</script>
